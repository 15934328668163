import http from './http'

export function getUser(data) {
    return http.post('/index/operator/getUser', data)
}
export function getAccept(data) {
    return http.post('/index/operator/getAccept', data)
}

export function getProcess(data) {
    return http.post('/index/operator/getProcess', data)
}

export function getFinish(data) {
    return http.post('/index/operator/getFinish', data)
}
export function setAcceptPeople(data) {
    return http.post('/index/operator/setAcceptPeople', data)
}
export function getGd(data) {
    return http.post('/index/operator/getGd', data)
}

export function setReturnAcceptPeople(data) {
    return http.post('/index/operator/setReturnAcceptPeople', data)
}

export function getFini(data) {
    return http.post('/index/operator/getFini', data)
}

export function getRole1(data) {
    return http.post('/index/operator/getRole1', data)
}
export function getAnalyze(data) {
    return http.post('/index/operator/getAnalyze', data)
}
